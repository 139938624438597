.product-container {
  width: 300px;
  height: 350px;
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 20px 10px;
  cursor: pointer;
  overflow: hidden;
  box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.2);
  transition: transform 0.2s linear;

  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transform: scale(1.05);
  }

  .img-container {
    width: 278px;
    height: 220px;
    position: relative;

    &:hover {
      .angle {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .product-img {
      border-radius: 5px;
      width: 278px;
      height: 220px;
    }
    .angle {
      display: none;
      font-size: xx-large;
      position: absolute;
      width: max-content;
      color: white;
      top: 100px;
      padding: 20px 10px;

      &:hover {
        background: transparent;
        background-color: rgba(240, 228, 228, 0.2);
      }
    }

    .left {
      left: 10px;
    }

    .right {
      right: 10px;
    }
  }

  .product-title {
    line-height: 1.1;
    width: 100%;
    height: 48px;
    margin: 10px 0;
    font-size: 22px;
    overflow: hidden;
  }

  .price-cart {
    // margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .product-price {
      color: gray;
      font-size: 18px;
      font-weight: 500;
    }
    // .add-to-cart {
    //   padding: 5px 10px;
    //   border-radius: 5px;
    //   &:hover {
    //     background-color: black;
    //     color: white;
    //   }
    // }
  }
}
