.datesDropdown {
    min-width:400px;
    max-width:550px;
    display: flex;
    flex-direction: row;
    gap:10px;
    margin-right:50px;

    .dateType-dropdown{
      min-width:150px;
      height:38px;
    }

  .date-pickers {
    min-width:350px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .dates-divider{
      padding:10px;
      // font-weight: bold;
    }


    .pickers {
      width:49%;
      label{
        padding:0px 5px;
      }
    }

    .dates {
      width: 100%;
    }
  }

  .date-input {
      width:100%;

    .field {
      margin: 0;
    }
    .date {
      width: 100% !important;
      padding: 0px 5px;
      height:38px;
      border: 0.5px solid rgb(226, 223, 223);
      border-radius: 4px;
    }
  }
}
