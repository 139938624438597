$breakpoint-mobile: 420px;

.App {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.main-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-left: 60px;
}

.green {
  color: green;
}
.red {
  color: red;
}

.gray,
.grey {
  color: gray;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.center {
  align-items: center;
}

.end {
  align-items: flex-end;
}

.row {
  flex-direction: row;
}

.column {
  flex-direction: column;
}

.align-start {
  justify-content: flex-start;
}

.align-end {
  justify-content: flex-end;
}

.space-between {
  justify-content: space-between;
}

.flex-break {
  flex-basis: 100%;
  height: 0;
}

.width-100 {
  width: 100%;
}

.width-50 {
  width: 50%;
}

.height-100 {
  height: 100%;
}

.scroll ::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.padding-left {
  padding-left: 5px;
}

.margin-bottom {
  margin-bottom: 5px;
}

.description {
  color: var(--primary-grey) !important;
  font-size: 12px;
}

.ellipsed {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 150px;
  display: block;
  overflow: hidden;
}

.pointer {
  cursor: pointer;
}

.capitalize {
  text-transform: capitalize;
}

input:focus {
  outline: none;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
  top: 0;
  left: 0;
}

.custom-scroll ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.light-grey {
  background-color: #f8f8f8;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.btn-link {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}

@media screen and (max-width: $breakpoint-mobile) {
  .main-content {
    padding-left: 0px;
  }
}
