.promotion-action-container {
  margin-top: 16px;

  .client-info {
    background-color: #7cfc0054;
    padding: 8px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .client-info.warn {
    background-color: #ff000054 !important;
    margin-bottom: 16px;
  }

  div.sms-promotion-container {
    div.promotion-info-action {
      margin-top: 10px;
      justify-content: space-between;
      height: 52px;

      .recurring-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  div.whatsapp-promotion-container {
    div.promotion-info-action {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .promotion-info-container {
        width: 100%;
      }

      .recurring-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 52px;
        margin: 6px 0;
      }
    }
  }

  div.promotion-action-buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    button {
      width: 100px;
      margin-bottom: 8px;
    }

    .error {
      background-color: #ff000054 !important;
      color: #333333 !important;
      padding: 6px 14px;
    }
  }

  .recurring-container {
    margin-top: 8px;
  }

  .recurring-options {
    margin-top: 16px;
    align-items: center;

    .checkbox {
      margin: 0 8px;
    }
  }

  .recurring-fields {
    margin-top: 16px;
    align-items: center;

    input,
    .selection.dropdown {
      margin-left: 8px;
    }
  }
}

.seperator {
  display: flex;
  align-self: center;
  padding: 0px 20px;
  font-weight: bold;
}

p.promotional-info.info {
  width: 100%;
  flex-direction: column;
  display: flex;
}

div.recurring-container {
  p.promotional-info.info {
    margin-bottom: 8px;
  }

  div.ui.toggle.checkbox {
    padding: 10px;
    border: 1px solid #b8b8fd;
    background-color: #b8b8fd42;
    border-radius: 10px;

    label {
      font-weight: bolder;
    }
  }
}
