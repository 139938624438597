.togglerContainer {
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  border-radius: 5px;

  .toggleLabel {
    font-weight: 600;
    color: var(--primary-black);
  }
}

.toggleOn {
  background-color: var(--theme-skyblue);
}

.toggleOff {
  background-color: var(--theme-grey);
}
