div.payslip {
  margin-top: 30px;
  max-width: 800px;
  min-height: 800px;

  div.details {
    flex-direction: row;
    display: flex;
  }

  .earning {
    flex: 1;
    padding: 4px;
    min-height: 500px;
  }

  .deduction {
    flex: 1;

    padding: 4px;
    margin-left: 10px;
    min-height: 500px;
  }

  div.details {
    border: 1px solid #c0c0c0;
  }

  .heading {
    justify-content: space-between;
    flex-direction: row;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    min-height: 50px;

    h4 {
      margin: 0;
      padding: 4px;
    }
  }

  .particular {
    padding-top: 8px;

    justify-content: space-between;
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    padding: 4px;
  }

  .payslip-header-details {
    min-height: 100px;
  }

  .payslip-header-details,
  .particular {
    border-bottom-width: 1px;
    border-bottom-color: #c0c0c0;
    border-bottom-style: solid;
  }

  .total {
    margin-top: 10px;
    text-align: right;
  }
}

@media print
{
  #side-bar, .header, button {
    display: none;
  }
  @page  {
    margin: 0;
  }
  .spacer {
    display: none;
  }
}

p.errors {
  margin-top: 10px;
  span {
    color: #ff5f5f;
  }
}
.payslip-details {
  display: flex;
  justify-content: space-around;
}