.search-container {
  width: fit-content;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px;
  border: 0.5px solid white;
  border-radius: 5px;
  gap: 10px;
  background-color: var(--theme-white);
  color: var(--primary-black);
}

.filter-search {
  width: 100px;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 2px;
  border: none;
  border-radius: 5px;
  background: transparent;
  transition: width 0.4s ease;
  outline: none;
  color: var(--primary-black);

  &:focus {
    width: 250px;
  }
}
