html {
  width: 100%;
  height: 100%;
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3b8dda;
  --toastify-color-success: #37e067;
  --toastify-color-warning: #e6a637;
  --toastify-color-error: #ff5866;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --primary-black: #000000;
  --primary-white: #ffffff;
  --primary-grey: darkgrey;
  --theme-white: #f3f3f3;
  --theme-grey: #e4e4e4;
  --theme-blue: #f3f3f3;
  --theme-skyblue: #abdcfe;
  --theme-red: #eb1d36;
  --theme-header: #f3f3f3;
  --theme-color-dropdown-button: rgba(0, 0, 0, 0.6);
  --theme-background-dropdown-button: #e0e1e2;
  --service-details-width: 120px;
  --white: #ffffff;
  --grey: gray;
  --light-grey: rgb(212, 212, 212);
  --black: #000000;
}

[data-theme="dark"] {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3b8dda;
  --toastify-color-success: #37e067;
  --toastify-color-warning: #e6a637;
  --toastify-color-error: #ff5866;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --primary-black: #ffffff;
  --primary-white: #000000;
  --theme-white: #1a1a1a;
  --theme-grey: #444444;
  --theme-blue: #0f4556;
  --theme-skyblue: #266c9e;
  --theme-header: #25383c;
  --theme-red: #a12141;
  --theme-color-dropdown-button: rgba(0, 0, 0, 0.6);
  --theme-background-dropdown-button: #e0e1e2;
  --service-details-width: 120px;
  --white: #ffffff;
  --grey: gray;
  --light-grey: rgb(212, 212, 212);
  --black: #000000;
}

.gradient-text {
  background: linear-gradient(124.56deg, #0BACF0 7.75%, #7431B8 86.71%);
  -webkit-background-clip: text;
  color: transparent;
}

[data-theme="dark"] input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

[data-theme="dark"] .styles__AsyncSelectContainer-sc-1w03vu0-26 > img {
  filter: invert(1);
}

[data-theme="dark"] .search__GeneralFieldContainer-sc-y3tmso-1 > img,
[data-theme="dark"] .styles__FormSubHeadingContainer-sc-1w03vu0-18 > img {
  filter: invert(1);
}

.ui.attached:not(.message) + .ui.attached.segment:not(.top) {
  background-color: var(--theme-white);
}

.ui.modal > .content {
  background-color: var(--theme-white) !important;
}

.ui.modal > .actions {
  background-color: var(--theme-grey) !important;
}

.invoice-table,
.invoice-table th,
.invoice-table td {
  background-color: var(--theme-white);
  color: var(--primary-black) !important;
}

.tnc {
  color: var(--primary-black) !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  background-color: var(--theme-white) !important;
  color: var(--primary-black) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.ui.checkbox label,
.custom-search > input,
.field > label,
.styles__SubHeadingText-sc-1w03vu0-19,
.styles__RadioLabel-sc-1w03vu0-22,
.bold,
.ui.dropdown > .text {
  color: var(--primary-black) !important;
}

.ui.form input {
  background-color: var(--theme-white) !important;
  color: var(--primary-black) !important;
  border-color: var(--primary-grey) !important;
}

.ui.toggle.checkbox label:before {
  border: 1px solid #cfd2cf !important;
}

.custom-search,
.ui.form textarea,
.ui.selection.dropdown,
.fields > input,
.ui > input,
.ui.dropdown {
  background-color: var(--theme-white) !important;
  color: var(--primary-black) !important;
  border: 1px solid !important;
  border-color: var(--primary-grey) !important;
}

.search__GeneralFieldContainer-sc-y3tmso-1 > input,
.styles__TextFieldContainer-sc-1w03vu0-4 > input,
.styles__TextFieldContainer-sc-1w03vu0-4 > span,
.styles__DateContainer-sc-1w03vu0-9 > input,
.styles__DateContainer-sc-1w03vu0-9 > span,
.styles__RadioButton-sc-1w03vu0-23,
.hover-table,
h2,
h4,
.fields > label,
.ui.tabular.menu .item,
.ui.tab {
  background-color: var(--theme-white) !important;
  color: var(--primary-black) !important;
}

::-webkit-datetime-edit-text {
  color: var(--primary-black) !important;
}
::-webkit-datetime-edit-month-field {
  color: var(--primary-black) !important;
}
::-webkit-datetime-edit-day-field {
  color: var(--primary-black) !important;
}
::-webkit-datetime-edit-year-field {
  color: var(--primary-black) !important;
}
::-webkit-datetime-edit {
  color: var(--primary-black) !important;
}
[data-theme="dark"] ::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
/* [data-theme="dark"] .calendar {
  filter: invert(1);
} */

.grey-background-theme {
  background-color: var(--theme-grey);
}

.text-theme-black {
  color: var(--primary-black) !important;
}

p.info {
  background-color: var(--theme-grey);
  border-radius: 5px;
  padding: 10px;
  color: var(--primary-black);
  overflow-wrap: break-word;
  font-weight: bold;
}

p.error {
  background-color: var(--toastify-color-error);
  border-radius: 5px;
  padding: 10px;
  color: var(--white);
  overflow-wrap: break-word;
  font-weight: bold;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif !important;
}

.clickable {
  &:hover {
    color: #0d71bb;
    text-decoration: underline;
    cursor: pointer;
  }
}
