.smsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  line-height: 1.6;
  background-color: var(--theme-white) !important;
  padding: 5px;
  border-radius: 5px;
}

.smsMessage {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  line-height: 2;
}

.textInput,
.inputDate {
  border: none !important;
  border-bottom: 1px solid black !important;
  width: 100px;
  border-radius: 0px !important;
  margin: 0 8px !important;
  padding: 0 !important;
  padding-bottom: 1px !important;
  font-size: 13px;
  outline: none !important;
}

.inputDate {
  width: 145px;
  background-color: var(--theme-white) !important;
}
