.analytics-accordion {
  border-radius: 15px;
  border: 1px solid lightgray;
  margin-bottom: 10px;
  background-color: #e8e8e8;

  .revenue {
    width: 200px;
    padding: 10px;
    border: 1px solid lightgray;
    height: 100px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    margin: auto 0;
    .total {
      display: flex;
      justify-content: space-between;
      align-items: center;
      i {
        font-size: 20px;
        color: lawngreen;
      }
      span {
        text-align: end;
        h2,
        h3 {
          margin: 0;
        }
        h2 {
          color: lightgray;
          font-weight: 100;
          font-size: 18px;
        }
        h3 {
          font-weight: 700;
        }
      }
    }
    .date {
      border-top: 1px solid lightgray;
      padding-top: 10px;
    }
  }
  .doughnut {
    canvas {
      height: 150px !important;
      width: 300px !important;
    }
    h4 {
      text-align: right;
      margin: 0;
    }
    .appointment {
      padding-right: 60px;
    }
    .top-5 {
      padding-right: 70px;
    }
    .staff {
      padding-right: 100px;
    }
  }
}
.chevron-icon {
  cursor: pointer;
  display: flex !important;
  justify-content: center;
  align-items: center;
  background: linear-gradient(124.56deg, #0BACF0 7.75%, #7431B8 86.71%);
  -webkit-background-clip: text;
  color: transparent;
}
.Stats-text {
  //color: var(--primary-black);
  font-size: 14px;
  font-weight: 600;
}

.Stats-Container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-radius: 5px;
  gap: 100px;
}
