.staff-revenue-container {
  width: 100%;
  min-height: 300px;

  .show-container {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    justify-content: space-between;
    align-items: flex-start;

    .handles {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
  }

  .service-container {
    margin-top: 20px;
    width: 100%;
    min-height: 200px;
    max-height: 500px;
    overflow: auto;
  }
}

@media screen and (max-width: 1400px) {
  .show-container {
    flex-direction: column;
  }
}

thead,
tfoot {
  font-weight: 700;
}
